import cart from '@/lib/cart'
import nanoajax from 'nanoajax'

export const actions = {
  getCart ({ commit, state }, payload) {
    commit('setLoading', true)

    return cart
      .get()
      .then(data => {
        commit('refreshCart', data)
      })
  },

  fetchRecommendedVariants ({ commit }) {
    nanoajax.ajax({
      url: `${window.Shopify.routes.root}cart?view=recommended-variants-item`
    }, (code, responseText) => {
      const response = JSON.parse(responseText)
      commit('setRecommendedVariants', response)
    })
  },

  removeItems ({commit, state}, data) {
    if (!data) {
      return
    }

    commit('setLoading', true)

    return cart
      .update(data)
      .then(cart => {
        commit('refreshCart', cart)
      })
  },

  updateItemsQuantity ({commit, state}, data) {
    if (!data) {
      return
    }

    commit('setLoading', true)

    return cart
      .update(data)
      .then(cart => {
        commit('refreshCart', cart)
      })
  },

  updateItemQuantity ({commit, state}, { quantity, id }) {
    if (!id) {
      return
    }

    commit('setLoading', true)

    const { items } = state.app.cart
    const item = items.find(i => i.variant_id === id)

    return cart
      .change({ quantity, id })
      .then(data => {
        commit('refreshCart', data)

        if (quantity === 0) {
          commit('setRemovedItem', item)
        }
      })
  },

  addToCart ({dispatch, state}, { id, quantity }) {
    const { items } = state.app.cart

    // Adds the requested `quantity` to the current item.quantity
    const item = items.find(i => i.variant_id === id)
    const totalQuantity = item
      ? item.quantity + quantity
      : quantity

    return dispatch('updateItemQuantity', { id, quantity: totalQuantity })
  },

  trackAddToCart ({dispatch, state}, { product, id }) {
    const variant = product.variants.find(({id: _id}) => _id === id)

    fbq && fbq('track', 'AddToCart', {
      value: (Number(variant.price) / 100),
      currency: 'USD',
      content_name: variant.name,
      content_type: 'product',
      content_ids: [id]
    })
  },

  async updateGiftMessage ({ commit }, message) {
    commit('updateGiftMessage', message)
    commit('giftMessageState', true)
  },

  async saveGiftMessage ({ commit, getters }) {
    commit('setLoading', true)
    try {
      await cart.updateNote(getters.giftMessage)
      commit('giftMessageState', false)
    } catch (error) {
      console.log(error)
    } finally {
      commit('setLoading', false)
    }
  }
}
